import { Loader } from '@mantine/core';

import { lazy, Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';
import { fetchWrapper } from 'src/core/lib/fetchWrapper';

import { AppLayout } from 'src/features/layout';
import { PreloaderNotProvider } from 'src/features/preloader';

const HomePage = lazy(() => import('src/features/home'));
const GalleryPage = lazy(() => import('src/features/gallery'));
const VideoGalleryPage = lazy(() => import('src/features/gallery/video'));
const ContactPage = lazy(() => import('src/features/contact'));
const TopsPage = lazy(() => import('src/features/tops'));
const Page = lazy(() => import('src/features/page'));

export const routes = [
  {
    path: '',
    element: (
      <AppLayout>
        <Suspense fallback={<PreloaderNotProvider />}>
          <Outlet />
        </Suspense>
      </AppLayout>
    ),
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'gallery/gallereya',
        element: <GalleryPage />,
      },
      {
        path: 'kontakty',
        element: <ContactPage />,
      },
      {
        path: 'top',
        element: <TopsPage />,
      },
      {
        path: 'video-gallery',
        element: <VideoGalleryPage />,
      },
      {
        path: ':slug',
        element: <Page />,
        loader: ({ params }) =>
          fetchWrapper.get({
            url: `/pages/${params.slug}`,
            type: 'public',
          }),
      },
    ],
  },
];

export function Router() {
  return useRoutes(routes);
}
