import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const rolenameSlice = createSlice({
  name: 'rolename',
  initialState,
  reducers: {
    setRolename(state, action) {
      state.data = action.payload;
    },
  },
});

export const rolenameActions = rolenameSlice.actions;
export const rolenameReducer = rolenameSlice.reducer;
