import { Box, Center, Stack } from '@mantine/core';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useTranslate } from 'src/locales';
import { useGetSettingsQuery } from 'src/services/settings/api';
import { useActions } from 'src/hooks/redux';
import { getLangFiled } from 'src/core/lib/func';

import { Footer } from './components/footer';
import { Slider } from './components/slider';
import { HeaderMenu } from './components/menu';
import { ParallaxHeader } from './components/parallax';

import classes from './Layout.module.css';

export function AppLayout() {
  const { currentLang } = useTranslate();

  const { data: settings } = useSelector((state) => state?.settings);
  const { setSetting } = useActions();
  const { data } = useGetSettingsQuery();

  useEffect(() => {
    if (data) {
      setSetting(data);
    }
  }, [data, setSetting]);

  return (
    <Box className={classes.root}>
      <Box className={classes.main}>
        <Box className={classes.content}>
          <Stack>
            <HeaderMenu />
            <ParallaxHeader />
            <Slider />

            <Box>
              <Outlet />
            </Box>
            <Footer
              message={getLangFiled(settings?.['sayt-podval'], 'value', currentLang?.value)}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}
