export const MENU = [
  {
    title: 'home',
    url: '/',
  },
  {
    title: 'start-game',
    url: '/nachat-igrat',
  },
  {
    title: 'info',
    url: 'https://forum.swordsmanonline.ru',
    css: ['anchor'],
  },
  {
    title: 'community',
    items: [
      {
        title: 'forum',
        url: 'https://forum.swordsmanonline.ru',
      },
      {
        title: 'discord',
        url: 'https://discordapp.com/invite/ZvvFeT2',
      },
      {
        title: 'vk',
        url: 'https://vk.com/playswordsman',
      },
      {
        title: 'facebook',
        url: 'https://www.facebook.com/swordsmanforvard',
      },
    ],
  },
  {
    title: 'guide',
    items: [
      {
        title: 'media',
        items: [
          {
            title: 'gallery',
            url: '/gallery/gallereya',
          },
          {
            title: 'video-gallery',
            url: '/video-gallery',
          },
        ],
      },
    ],
  },
  {
    title: 'contacts',
    url: '/kontakty',
  },
];
