import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

const gildApi = createApi({
  reducerPath: 'api/gild',
  baseQuery,
  endpoints: (build) => ({
    getGild: build.query({
      query: (payload) => ({
        url: 'top/gilds',
        method: 'GET',
        params: payload,
      }),
    }),
  }),
});

export const { useGetGildQuery } = gildApi;

export default gildApi;
