import { Box, Center, Image, Loader, MantineProvider, rem, Stack } from '@mantine/core';

import logo from 'src/assets/images/logo.webp';
import { theme } from 'src/theme';

import classes from './Preloader.module.css';

export function Preloader() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <PreloaderNotProvider />
    </MantineProvider>
  );
}

export function PreloaderNotProvider() {
  return (
    <Box className={classes.root}>
      <Center h="100%">
        <Stack justify="center" gap={rem(50)}>
          <Image src={logo} w={rem(322)} h={rem(175)} alt="Swordsman Online" />
          <Box className={classes.loader}>
            <div className={classes.dots} />
            <div className={classes.dots} />
            <div className={classes.dots} />
            <div className={classes.dots} />
            <div className={classes.dots} />
            <div className={classes.dots} />
          </Box>
        </Stack>
      </Center>
    </Box>
  );
}
