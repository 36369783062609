import { useDispatch } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import { settingsActions } from 'src/services/settings/slice';
import { topActions } from 'src/services/top/slice';
import { gildActions } from 'src/services/gild/slice';
import { generalActions } from 'src/services/general/slice';
import { rolenameActions } from 'src/services/rolename/slice';

const actions = {
  ...settingsActions,
  ...topActions,
  ...gildActions,
  ...generalActions,
  ...rolenameActions,
};

export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
