import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

const configApi = createApi({
  reducerPath: 'api/configs',
  baseQuery,
  endpoints: (build) => ({
    getConfig: build.query({
      query: (payload) => ({
        url: '/configs',
        method: 'GET',
        params: payload,
      }),
    }),
  }),
});

export const { useGetConfigQuery } = configApi;

export default configApi;
