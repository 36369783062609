import { BackgroundImage, Center, Text, UnstyledButton } from '@mantine/core';

import cx from 'clsx';

import classes from './ButtonImage.module.css';

export function ButtonImage({ src, title, ...props }) {
  return (
    <BackgroundImage
      component={UnstyledButton}
      src={src}
      className={cx({
        [classes.root]: true,
        [classes.animation]: !!props.animation,
      })}
      {...props}
      style={{
        animationName: props.animation,
      }}
    >
      <Center>
        <Text c="white" fw={500} className={classes.text} fz={props?.fz} lh={props?.lh} px="md">
          {title}
        </Text>
      </Center>
    </BackgroundImage>
  );
}
