import {
  IconAt,
  IconBrandVk,
  IconBrandSkype,
  IconBrandDiscord,
  IconBrandFacebook,
} from '@tabler/icons-react';

export const CONTACTS = [
  {
    title: 'E-mail',
    description: 'support@swordsmanonline.ru',
    link: 'mailto:support@swordsmanonline.ru',
    icon: IconAt,
  },
  {
    title: 'Skype',
    description: 'swordsmanforvard',
    link: 'skype:swordsmanforvard',
    icon: IconBrandSkype,
  },
  {
    title: 'Discord',
    description: 'Перейти',
    link: 'https://discordapp.com/invite/ZvvFeT2',
    icon: IconBrandDiscord,
  },
  {
    title: 'Группа Вконтакте',
    description: 'Перейти',
    link: 'https://vk.com/playswordsman',
    icon: IconBrandVk,
  },
  {
    title: 'Группа FaceBook',
    description: 'Перейти',
    link: 'https://www.facebook.com/swordsmanforvard',
    icon: IconBrandFacebook,
  },
];
