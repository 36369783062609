import { createSlice } from '@reduxjs/toolkit';

import { initialFilter } from 'src/services/state';

const initialState = {
  data: {},
  filter: initialFilter,
  rows: [],
};

export const topSlice = createSlice({
  name: 'top',
  initialState,
  reducers: {
    setTop(state, action) {
      state.data = action.payload;
    },
    setTops_data(state, action) {
      state.rows = action.payload;
    },
    setTops(state, action) {
      state.rows = action.payload.rows;
      state.filter.total = action.payload.total;
      state.filter.total_pages = action.payload.total_pages;
      state.filter.order = action.payload.order;
      state.filter.orderBy = action.payload.orderBy;
    },
    setTops_page(state, action) {
      state.filter.page = action.payload;
    },
    setTops_sort(state, action) {
      state.filter.order = action.payload.order;
      state.filter.orderBy = action.payload.orderBy;
    },
  },
});

export const topActions = topSlice.actions;
export const topReducer = topSlice.reducer;
