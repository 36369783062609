import { useEffect } from 'react';
import { useMounted } from '@mantine/hooks';
import { nprogress, NavigationProgress } from '@mantine/nprogress';

export function ProgressBar() {
  const mounted = useMounted();

  useEffect(() => {
    console.log(mounted);
    if (!mounted) {
      nprogress.start();
    }

    if (mounted) {
      nprogress.complete();
    }

    return () => {
      nprogress.complete();
    };
  }, [mounted]);

  return (
    <NavigationProgress
      portalProps={{
        'aria-label': 'Progress',
      }}
      aria-labelledby="Progress"
    />
  );
}
