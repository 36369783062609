import { fetchBaseQuery } from '@reduxjs/toolkit/query';

import { localStorageGetItem } from 'src/core/lib/storage-available';
import { VITE_URL_API } from 'src/global-config';
import { fallbackLng } from 'src/locales';

const lng = localStorageGetItem('i18nextLng', fallbackLng);

export const baseQuery = fetchBaseQuery({
  baseUrl: `${VITE_URL_API}/v1`,
  prepareHeaders: (headers, api) => {
    const state = api.getState();
    const token = state?.token?.access;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    headers.set('Accept-Language', lng);

    return headers;
  },
});
