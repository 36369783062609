import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setGeneral(state, action) {
      state.data = action.payload;
    },
  },
});

export const generalActions = generalSlice.actions;
export const generalReducer = generalSlice.reducer;
