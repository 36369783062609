import ru from 'src/assets/images/flags/ru.png';
import en from 'src/assets/images/flags/gb.png';
import cn from 'src/assets/images/flags/cn.png';

export const allLangs = [
  {
    flag: ru,
    value: 'ru',
    label: 'RU', // 'Русский',
    countryCode: 'RU',
    adapterLocale: 'ru',
    numberFormat: { code: 'ru-RU', currency: 'RUB' },
    systemValue: {
      components: {},
    },
  },
  {
    flag: en,
    value: 'en',
    label: 'ENG', // 'English',
    countryCode: 'GB',
    adapterLocale: 'en',
    numberFormat: { code: 'en-US', currency: 'USD' },
    systemValue: {
      components: {},
    },
  },
  // {
  //   flag: cn,
  //   value: 'cn',
  //   label: '中文', // 'Chinese',
  //   countryCode: 'CN',
  //   adapterLocale: 'zh-cn',
  //   numberFormat: { code: 'zh-CN', currency: 'CNY' },
  //   systemValue: {
  //     components: {},
  //   },
  // },
];
