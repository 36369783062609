import { VITE_URL_API } from 'src/global-config';

import { sessionStorageGetItem } from 'src/core/lib/storage-available';

const token = sessionStorageGetItem('persist:token');

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method) {
  return ({ url, body, jsonBody = true, log = false }) => {
    const map = new Map();

    map.set('brand', 'int');

    const requestOptions = {
      method,
      headers: {},
    };

    if (body) {
      if (jsonBody) {
        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.body = JSON.stringify(body, (key, value) => {
          switch (map.get(key)) {
            case 'string':
              return value ?? '';
            case 'int':
              return value ?? '';
            default:
              return value;
          }
        });
      } else {
        requestOptions.body = body;
      }
    }

    const access = JSON.parse(token)?.access;

    if (access) {
      requestOptions.headers.Authorization = `Bearer ${JSON.parse(access)}`;
    }

    return fetch(`${VITE_URL_API}/v1${url}`, requestOptions)
      .then(handleResponse)
      .catch((response) => Promise.reject(response));
  };
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.messages && data.messages.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
