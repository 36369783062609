import {
  BackgroundImage,
  Badge,
  Box,
  Center,
  Container,
  Grid,
  rem,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { MouseParallax } from 'react-just-parallax';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ButtonImage } from 'src/core/components/fields/button/image';
import { getLangFiled } from 'src/core/lib/func';
import { useTranslate } from 'src/locales';

import bg from 'src/assets/images/swordsman-background.webp';
import logo from 'src/assets/images/logo.webp';
import btn from 'src/assets/images/btn.webp';
import linglong from 'src/assets/images/swordsman-linglong-2.webp';
import leaves_small from 'src/assets/images/leaves-small11.webp';
import leaves_big from 'src/assets/images/leaves-big11.webp';

import classes from './Parallax.module.css';

export function ParallaxHeader() {
  const { currentLang, t } = useTranslate(['navbar']);
  const { data: settings } = useSelector((state) => state?.settings);

  return (
    <Box
      pos="relative"
      style={{
        backgroundImage: `url(${bg})`,
      }}
      className={classes.root}
    >
      <Box pos="absolute" top={0} right={50} bottom={0} left={0} visibleFrom="lg">
        <MouseParallax strength={0.015} zIndex={250}>
          <BackgroundImage className={classes.linglong} src={linglong} />
        </MouseParallax>
      </Box>
      <Box pos="absolute" top={0} right={50} bottom={0} left={0} visibleFrom="lg">
        <MouseParallax strength={0.03} zIndex={220}>
          <BackgroundImage className={classes.leaves_small} src={leaves_small} />
        </MouseParallax>
      </Box>
      <Box pos="absolute" top={0} right={50} bottom={0} left={0} visibleFrom="lg">
        <MouseParallax strength={0.045} zIndex={210}>
          <BackgroundImage className={classes.leaves_big} src={leaves_big} />
        </MouseParallax>
      </Box>
      <Box pos="absolute" top={100} right={0} bottom={0} left={0} className={classes.content}>
        <Container size="responsive">
          <Grid>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <Stack>
                <Center>
                  <BackgroundImage
                    component={Link}
                    to="/"
                    className={classes.logo}
                    src={logo}
                    w={322}
                    h={194.7}
                    area-label="Swordsman Online"
                  />
                </Center>
                <Title order={2} c="forvard" ta="center">
                  {getLangFiled(settings?.['nazvanie-sayte'], 'value', currentLang?.value)}
                </Title>
                <Text ta="center">
                  {getLangFiled(settings?.['opisanie-sayte'], 'value', currentLang?.value)}
                </Text>
                <Center>
                  <Stack align="center">
                    <Stack align="center" gap={0}>
                      <Badge variant="transparent" color="green">
                        online
                      </Badge>
                      <Text fw={300} c="dimmed">
                        {t('development-mode')}
                      </Text>
                    </Stack>
                    <ButtonImage
                      component={Link}
                      to="/nachat-igrat"
                      src={btn}
                      title={t('start-game')}
                      w={rem(280)}
                      h={rem(60)}
                      fz={rem(27)}
                      lh={rem(60)}
                      animation="start-game"
                    />
                  </Stack>
                </Center>
              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
