import {
  ActionIcon,
  Anchor,
  BackgroundImage,
  Group,
  Image,
  rem,
  Stack,
  TypographyStylesProvider,
  useComputedColorScheme,
} from '@mantine/core';

import logo from 'src/assets/images/logo.webp';
import footer_back from 'src/assets/images/footer-back.webp';
import footer_back_dark from 'src/assets/images/footer-back-dark.webp';

import { CONTACTS } from 'src/_mock/contact';

import classes from './Footer.module.css';

import { FooterMenu } from '../menu';

export function Footer({ message }) {
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  return (
    <BackgroundImage
      src={computedColorScheme === 'light' ? footer_back : footer_back_dark}
      p="xl"
      className={classes.root}
    >
      <Stack gap="xl">
        <Group justify="space-between">
          <Image src={logo} w="auto" h={90} alt="Swordsman Online" />

          <FooterMenu />

          <Stack>
            <Group>
              {CONTACTS.map((_, i) => (
                <ActionIcon
                  key={i}
                  variant="subtle"
                  component={Anchor}
                  href={_.link}
                  target="_blank"
                  area-label={_.title}
                >
                  <_.icon style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
              ))}
            </Group>
            <Group justify="space-between">
              <Anchor target="_blank" rel="noopener noreferrer" href="https://freekassa.com">
                <Image
                  src={
                    computedColorScheme === 'light'
                      ? 'https://cdn.freekassa.com/banners/small-white-1.png'
                      : 'https://cdn.freekassa.com/banners/small-dark-1.png'
                  }
                  title="Прием платежей на сайте"
                  alt="Прием платежей на сайте"
                  width={88}
                  height={31}
                  ml="auto"
                />
              </Anchor>
              <Anchor
                target="_blank"
                rel="nofollow"
                href="https://metrika.yandex.ru/stat/?id=26186340&amp;from=informer"
              >
                <Image
                  src="https://informer.yandex.ru/informer/26186340/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                  alt="Яндекс.Метрика"
                  title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                  datacid={26186340}
                  datalang="ru"
                  className="ym-advanced-informer"
                  w={rem(88)}
                  h={rem(31)}
                  style={{ border: 0 }}
                />
              </Anchor>
            </Group>
          </Stack>
        </Group>
        <TypographyStylesProvider c="dimmed" className={classes.message}>
          <div
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </TypographyStylesProvider>
      </Stack>
    </BackgroundImage>
  );
}
