export const fallbackLng = 'ru';
export const languages = ['ru', 'en', 'cn'];
export const defaultNS = 'common';
export const cookieName = 'i18next';

export function i18nOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    lng,
    fallbackLng,
    ns,
    defaultNS,
    fallbackNS: defaultNS,
    supportedLngs: languages,
  };
}

export const changeLangMessages = {
  ru: {
    success: 'Язык был изменен!',
    error: 'Ошибка смены языка!',
    loading: 'Загрузка...',
  },
  en: {
    success: 'Language has been changed!',
    error: 'Error changing language!',
    loading: 'Loading...',
  },
  cn: {
    success: '语言已更改！',
    error: '更改语言时出错！',
    loading: '加载中...',
  },
};
