import {
  Anchor,
  BackgroundImage,
  Box,
  Burger,
  Center,
  Container,
  Drawer,
  Group,
  Menu,
  MenuDropdown,
  rem,
  Stack,
  Text,
  useComputedColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';

import cx from 'clsx';
import { Link } from 'react-router-dom';

import { useTranslate } from 'src/locales';

import logo from 'src/assets/images/logo.webp';
import btn from 'src/assets/images/btn.webp';
import navbar_back from 'src/assets/images/navbar-back.webp';
import navbar_back_dark from 'src/assets/images/navbar-back-dark.webp';

import { MENU } from 'src/_mock/menu';
import { LanguagePicker } from 'src/core/components/language';
import { ColorIcon } from 'src/core/components/color';
import { ButtonImage } from 'src/core/components/fields/button/image';
import { VITE_URL_CP } from 'src/global-config';

import classes from './Menu.module.css';

export function HeaderMenu() {
  const { t } = useTranslate(['navbar']);
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });

  const [opened, { close, toggle }] = useDisclosure(false);

  return (
    <Box className={classes.sticky}>
      <Box className={classes.root}>
        <BackgroundImage
          src={computedColorScheme === 'light' ? navbar_back : navbar_back_dark}
          className={classes.header}
        />
        <Container size="responsive" py="md" className={classes.container}>
          <Group justify="space-between">
            <Group>
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
              <BackgroundImage
                component={Link}
                to="/"
                src={logo}
                w={rem(108)}
                h={rem(65.3)}
                className={classes.logo}
                visibleFrom="xs"
                area-label="Swordsman Online"
              />
            </Group>
            <Group visibleFrom="lg">
              {MENU?.map((_, i) => (
                <Item key={i} {..._} />
              ))}
            </Group>
            <Group>
              <ButtonImage
                src={btn}
                title={t('cp')}
                component={Anchor}
                href={VITE_URL_CP}
                underline="never"
                w={rem(220)}
                h={rem(43.69)}
                fz={rem(18)}
                lh={rem(43.69)}
                visibleFrom="sm"
              />
              {/* <ColorIcon /> */}
              <LanguagePicker />
            </Group>
          </Group>
        </Container>
        <Drawer
          opened={opened}
          onClose={close}
          zIndex={300}
          overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        >
          <Stack>
            {MENU?.map((_, i) => (
              <Item key={i} {..._} drawer toggle={toggle} />
            ))}
          </Stack>
        </Drawer>
      </Box>
    </Box>
  );
}

export function FooterMenu() {
  return (
    <Group visibleFrom="sm">
      {MENU?.map((_, i) => (
        <Item key={i} {..._} />
      ))}
    </Group>
  );
}

function Item({ title, url, css, items, position, drawer, pl = 0, toggle }) {
  const { t } = useTranslate(['navbar']);

  if (items && items.length > 0) {
    if (drawer) {
      return (
        <>
          <Text
            className={cx({
              [classes.link]: true,
              [classes.anchor]: css?.includes('anchor'),
            })}
            pl={pl * 2}
          >
            {t(title)}
          </Text>
          {items?.map((_, i) => (
            <Item {..._} position="right-start" drawer={drawer} pl={pl + 10} toggle={toggle} />
          ))}
        </>
      );
    }

    return (
      <Menu trigger="hover" withinPortal position={position}>
        <Menu.Target>
          <Group gap={0}>
            <Text
              className={cx({
                [classes.link]: true,
                [classes.anchor]: css?.includes('anchor'),
              })}
            >
              {t(title)}
            </Text>
            {!position && <IconChevronDown size={14} string={1.5} />}
            {position && <IconChevronRight size={14} string={1.5} />}
          </Group>
        </Menu.Target>
        <MenuDropdown>
          {items?.map((_, i) => (
            <Menu.Item key={i}>
              <Item {..._} position="right-start" />
            </Menu.Item>
          ))}
        </MenuDropdown>
      </Menu>
    );
  }

  return (
    <Anchor
      component={Link}
      to={url}
      className={cx({
        [classes.link]: true,
        [classes.anchor]: css?.includes('anchor'),
      })}
      underline="never"
      pl={pl * 2}
      onClick={() => drawer && toggle()}
    >
      {t(title)}
    </Anchor>
  );
}
