/* eslint-disable perfectionist/sort-imports */

import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/zh-cn';

import { DatesProvider } from '@mantine/dates';

import dayjs from 'dayjs';

import { useTranslate } from './use-locales';

export function LocalizationProvider({ children }) {
  const { currentLang } = useTranslate();

  dayjs.locale(currentLang.adapterLocale);

  return <DatesProvider settings={{ locale: currentLang.adapterLocale }}>{children}</DatesProvider>;
}
