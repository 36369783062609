import { Carousel } from '@mantine/carousel';
import {
  Anchor,
  BackgroundImage,
  Box,
  Center,
  em,
  Group,
  Image,
  Paper,
  rem,
  Stack,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import clsx from 'clsx';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';

import { ButtonImage } from 'src/core/components/fields/button/image';

import btn from 'src/assets/images/btn.webp';

import slider_bg from 'src/assets/images/slide-back-new.webp';
import slider_arrow_right from 'src/assets/images/right-arrow.webp';
import slider_arrow_left from 'src/assets/images/left-arrow.webp';

import slider_1 from 'src/assets/images/slide-back-111.webp';
import slider_2 from 'src/assets/images/slide-back-211.webp';
import slider_3 from 'src/assets/images/slide-back-311.webp';
import slider_4 from 'src/assets/images/slide-back-411.webp';

import slider_icon_1 from 'src/assets/images/slider-icon-111.webp';
import slider_icon_2 from 'src/assets/images/slider-icon-211.webp';
import slider_icon_3 from 'src/assets/images/slider-icon-311.webp';
import slider_icon_4 from 'src/assets/images/slider-icon-411.webp';

import classes from './Slider.module.css';

const moskdata = [
  {
    bg: slider_1,
    icon: slider_icon_1,
    text: {
      title: 'Бонусы',
      message: 'переходящим альянсам!',
    },
    url: '/',
  },
  {
    bg: slider_2,
    icon: slider_icon_2,
    text: {
      title: 'Бонусы ',
      message: 'новым игрокам!',
    },
    url: 'https://forum.swordsmanonline.ru/threads/%D0%91%D0%BE%D0%BD%D1%83%D1%81%D1%8B-%D0%9D%D0%BE%D0%B2%D1%8B%D0%BC-%D0%98%D0%B3%D1%80%D0%BE%D0%BA%D0%B0%D0%BC.1320/',
  },
  {
    bg: slider_3,
    icon: slider_icon_3,
    text: {
      title: 'Донат-валюта',
      message: 'бесплатно!',
    },
    url: 'https://forum.swordsmanonline.ru/threads/%D0%91%D0%B5%D1%81%D0%BF%D0%BB%D0%B0%D1%82%D0%BD%D0%B0%D1%8F-%D0%94%D0%BE%D0%BD%D0%B0%D1%82-%D0%92%D0%B0%D0%BB%D1%8E%D1%82%D0%B0.1318/',
  },
  {
    bg: slider_4,
    icon: slider_icon_4,
    text: {
      wb: 'break-all',
      title: 'Реф. система',
      message: 'с постоянными бонусами!',
    },
    url: 'https://forum.swordsmanonline.ru/threads/%D0%A0%D0%B5%D1%84%D0%B5%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B0.1311/',
  },
];

export function Slider() {
  const autoplay = useRef(Autoplay({ delay: 4000 }));
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  return (
    <BackgroundImage
      src={slider_bg}
      style={{
        backgroundSize: 'cover',
      }}
    >
      <Box
        className={clsx({
          [classes.box_container]: true,
          [classes.is_mobile]: isMobile,
        })}
        py="xl"
      >
        <Carousel
          classNames={classes}
          slideSize={{ base: '100%', xs: '50%', lg: '25%' }}
          slideGap={{ base: 0, sm: 'md' }}
          loop
          align="start"
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          previousControlProps={{
            'aria-level': 'previous',
          }}
          previousControlIcon={<BackgroundImage src={slider_arrow_left} w={rem(20)} h={rem(36)} />}
          nextControlProps={{
            'aria-level': 'next',
          }}
          nextControlIcon={<BackgroundImage src={slider_arrow_right} w={rem(20)} h={rem(36)} />}
        >
          {[...moskdata, ...moskdata].map((_, i) => (
            <Carousel.Slide key={i}>
              <Item {..._} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Box>
    </BackgroundImage>
  );
}

function Item({ bg, icon, text: { wb, title, message }, url }) {
  return (
    <BackgroundImage className={classes.bslide_bg} src={bg} component={Paper} h={rem(126)} p="md">
      <Stack>
        <Group wrap="nowrap" align="flex-start">
          <Image src={icon} width={94} height={94} alt={`${title} ${message}`} />
          <Stack>
            <Title
              order={3}
              style={{
                wordBreak: `${wb}`,
              }}
            >
              <span
                style={{
                  color: 'var(--mantine-primary-color-filled)',
                }}
              >
                {title}
              </span>{' '}
              <span>{message}</span>
            </Title>
            <ButtonImage
              src={btn}
              title="Подробнее"
              w={rem(124)}
              fz={rem(16)}
              component={Anchor}
              href={url}
            />
          </Stack>
        </Group>
      </Stack>
    </BackgroundImage>
  );
}
