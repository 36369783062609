import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

const settingsApi = createApi({
  reducerPath: 'api/settings',
  baseQuery,
  endpoints: (build) => ({
    getSettings: build.query({
      query: (payload) => ({
        url: '/settings',
        method: 'GET',
        params: payload,
      }),
    }),
    getSetting: build.query({
      query: (payload) => ({
        url: `/settings/${payload.key}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSettingsQuery, useGetSettingQuery } = settingsApi;

export default settingsApi;
