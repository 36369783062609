import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

const generalApi = createApi({
  reducerPath: 'api/general',
  baseQuery,
  endpoints: (build) => ({
    getGeneral: build.query({
      query: (payload) => ({
        url: '/top/general',
        method: 'GET',
        params: payload,
      }),
    }),
  }),
});

export const { useGetGeneralQuery } = generalApi;

export default generalApi;
