import { createSlice } from '@reduxjs/toolkit';

import { initialFilter } from 'src/services/state';

const initialState = {
  data: {},
  filter: initialFilter,
  rows: [],
};

export const gildSlice = createSlice({
  name: 'gild',
  initialState,
  reducers: {
    setGild(state, action) {
      state.data = action.payload;
    },
    setGilds(state, action) {
      state.rows = action.payload.rows;
      state.filter.total = action.payload.total;
      state.filter.total_pages = action.payload.total_pages;
    },
    setGilds_page(state, action) {
      state.filter.page = action.payload;
    },
  },
});

export const gildActions = gildSlice.actions;
export const gildReducer = gildSlice.reducer;
