import { isNull } from './isNull';

export function getOrderMethod(method) {
  switch (parseInt(method, 10)) {
    case 0:
      return 'UnitPay';
    case 1:
      return 'FreeKassa';
    case 2:
      return 'Manual';
    case 3:
      return 'Enot';
    default:
      return 'Не найден';
  }
}

export function getOrderStatus(status) {
  switch (parseInt(status, 10)) {
    case 0:
      return 'Processing';
    case 1:
      return 'Error';
    case 2:
      return 'Successful';
    default:
      return 'Не найден';
  }
}

export function getOrderStatusColor(status) {
  switch (parseInt(status, 10)) {
    case 0:
      return 'orange'; // Processing
    case 1:
      return ''; // Error
    case 2:
      return 'green'; // Successful
    default:
      return 'gray'; // Не найден
  }
}

export function getOrderCurrency(currency) {
  switch (parseInt(currency, 10)) {
    case 0:
      return 'RUB';
    case 1:
      return 'USD';
    default:
      return 'Не найден';
  }
}

export function getLangFiled(row, name, lang) {
  const value = row?.[name]?.[lang] || '';

  if (isNull(value)) {
    return row?.[name]?.ru || '';
  }

  return value;
}

export function parseBool(value) {
  if (value) {
    switch (value?.toString()) {
      case 'true':
      case 't':
      case '1':
        return true;
      default:
        return false;
    }
  }

  return false;
}

export function topSec(totalSeconds) {
  // Вычисление компонентов времени
  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;
  const secondsInMonth = 30 * secondsInDay; // Упрощённо, 30 дней в месяце
  const secondsInYear = 12 * secondsInMonth; // Упрощённо, 12 месяцев в году

  let remainingSeconds = totalSeconds;

  const years = Math.floor(remainingSeconds / secondsInYear);
  remainingSeconds %= secondsInYear;

  const months = Math.floor(remainingSeconds / secondsInMonth);
  remainingSeconds %= secondsInMonth;

  const days = Math.floor(remainingSeconds / secondsInDay);
  remainingSeconds %= secondsInDay;

  const hours = Math.floor(remainingSeconds / secondsInHour);
  remainingSeconds %= secondsInHour;

  const minutes = Math.floor(remainingSeconds / secondsInMinute);
  const seconds = remainingSeconds % secondsInMinute;

  let res = '';

  if (years > 0) res += `${years} Y. `;
  if (months > 0) res += `${months} M. `;
  if (days > 0) res += `${days} d. `;
  if (hours > 0) res += `${hours} h. `;
  if (minutes > 0) res += `${minutes} m. `;
  if (seconds > 0) res += `${seconds} s. `;

  return res.trim();
}
