import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';

import { store, persistor } from 'src/store';

import App from './App';
import { Preloader } from './features/preloader';

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <PersistGate loading={<Preloader />} persistor={persistor}>
      <Suspense fallback={<Preloader />}>
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
);
