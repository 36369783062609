import { createSlice } from '@reduxjs/toolkit';

import { initialFilter } from 'src/services/state';

const initialState = {
  data: {},
  filter: initialFilter,
  rows: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSetting(state, action) {
      state.data = action.payload;
    },
    setSettings(state, action) {
      state.rows = action.payload.rows;
      state.filter.total = action.payload.total;
      state.filter.total_pages = action.payload.total_pages;
    },
    setSettings_page(state, action) {
      state.filter.page = action.payload;
    },
  },
});

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;
