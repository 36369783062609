import { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';
import { UnstyledButton, Menu, Image, Group } from '@mantine/core';

import { allLangs, useTranslate } from 'src/locales';

import classes from './LanguagePicker.module.css';

export function LanguagePicker() {
  const { onChangeLang, currentLang } = useTranslate();

  const [opened, setOpened] = useState(false);

  const items = allLangs.map((item) => (
    <Menu.Item
      leftSection={<Image src={item.flag} width={22} height={15} alt="Language" />}
      onClick={() => onChangeLang(item.value)}
      key={item.value}
    >
      {item.label}
    </Menu.Item>
  ));

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
      w={128}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control} data-expanded={opened || undefined}>
          <Group gap="xs">
            <Image src={currentLang.flag} width={22} height={15} alt="Language" />
            <span className={classes.label}>{currentLang.label}</span>
          </Group>
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  );
}
