import 'src/global.css';
import '@mantine/core/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/carousel/styles.css';

import 'react-photo-album/rows.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { LoadingOverlay, MantineProvider } from '@mantine/core';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { theme } from 'src/theme';
import { routes } from 'src/routes';
import { ProgressBar } from 'src/core/components/progress-bar';
import { I18nProvider, LocalizationProvider } from './locales';
import { PreloaderNotProvider } from './features/preloader';

const router = createBrowserRouter(routes);

function App() {
  return (
    <I18nProvider>
      <LocalizationProvider>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <ProgressBar />
          <Notifications position="top-right" zIndex={1450} />
          <ModalsProvider>
            <RouterProvider
              future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
                v7_fetcherPersist: true,
                v7_normalizeFormMethod: true,
                v7_partialHydration: true,
                v7_skipActionStatusRevalidation: true,
              }}
              router={router}
              fallbackElement={
                // <LoadingOverlay
                //   overlayProps={{
                //     blur: 2,
                //   }}
                //   loaderProps={{
                //     type: 'dots',
                //   }}
                // />
                <PreloaderNotProvider />
              }
            />
          </ModalsProvider>
        </MantineProvider>
      </LocalizationProvider>
    </I18nProvider>
  );
}

export default App;
