import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access: null,
  refresh: null,
};

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state, action) {
      const data = action.payload;
      state.access = data.accessToken;
      state.refresh = data.refreshToken;
    },
    removeToken(state, action) {
      state.access = null;
      state.refresh = null;
    },
  },
});

export const tokenActions = tokenSlice.actions;
export const tokenReducer = tokenSlice.reducer;
