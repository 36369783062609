import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../base';

const rolenameApi = createApi({
  reducerPath: 'api/rolename',
  baseQuery,
  endpoints: (build) => ({
    getRolename: build.query({
      query: (payload) => ({
        url: '/server/rolename',
        method: 'GET',
        params: payload,
      }),
    }),
  }),
});

export const { useGetRolenameQuery } = rolenameApi;

export default rolenameApi;
