import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import configApi from 'src/services/configs/api';
import generalApi from 'src/services/general/api';
import { generalReducer } from 'src/services/general/slice';
import gildApi from 'src/services/gild/api';
import { gildReducer } from 'src/services/gild/slice';
import rolenameApi from 'src/services/rolename/api';
import { rolenameReducer } from 'src/services/rolename/slice';
import settingsApi from 'src/services/settings/api';
import { settingsReducer } from 'src/services/settings/slice';

import { tokenReducer } from 'src/services/token/slice';
import topApi from 'src/services/top/api';
import { topReducer } from 'src/services/top/slice';

const tokenPersistConfig = {
  key: 'token',
  storage: sessionStorage,
};

export const errorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.log('errorLogger');
    console.log(action);
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    token: persistReducer(tokenPersistConfig, tokenReducer),

    [configApi.reducerPath]: configApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [topApi.reducerPath]: topApi.reducer,
    [gildApi.reducerPath]: gildApi.reducer,
    [generalApi.reducerPath]: generalApi.reducer,
    [rolenameApi.reducerPath]: rolenameApi.reducer,

    settings: settingsReducer,
    top: topReducer,
    gild: gildReducer,
    general: generalReducer,
    rolename: rolenameReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      errorLogger,
      configApi.middleware,
      settingsApi.middleware,
      topApi.middleware,
      gildApi.middleware,
      generalApi.middleware,
      rolenameApi.middleware
    ),
});

setupListeners(store.dispatch);

export const RootState = store.getState;

export const persistor = persistStore(store);
