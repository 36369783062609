import 'src/assets/fonts/beaufort-for-lol/stylesheet.css';

import cx from 'clsx';

import { createTheme, Container } from '@mantine/core';
import { generateColors } from '@mantine/colors-generator';

import classes from './Swordsman.module.css';

export const theme = createTheme({
  fontFamily: 'BeaufortforLOL, Arial,sans-serif',
  primaryColor: 'forvard',
  colors: {
    forvard: generateColors('#e21345'),
    // forvard: generateColors('#410d09'), // #2d0a07
  },
  headings: {
    fontFamily: 'BeaufortforLOL, Arial,sans-serif',
    fontWeight: 400,
    sizes: {
      h1: {
        fontSize: '34px',
        fontWeight: 900,
        lineHeight: '17px',
      },
      h2: {
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '32px',
      },
      h3: {
        fontSize: '22px',
        fontWeight: 500,
        lineHeight: '22px',
      },
      h4: {
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '18px',
      },
      h5: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
      },
      h6: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '14px',
      },
    },
  },
  components: {
    Container: Container.extend({
      classNames: (_, { size }) => ({
        root: cx({ [classes.responsiveContainer]: size === 'responsive' }),
      }),
    }),
  },
});
